/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { Empty, Layout, Spin } from 'antd';
import { renameFile } from '@/services/documentService';
// import SkeletonNode from 'antd/es/skeleton/Node';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DirectoryTreeProps } from 'antd/es/tree';
import customNotification from '@/utils/notification';
import {
  filterItemsInDirectory,
  getFileNameFromKey,
  generateUniqueFileName,
} from './DocumentsHelper';
import { useDocumentState } from './DocumentContext';
import { File } from './File';
import DirectoryTreeComponent from './DirectoryTree';
import ConfirmationModal from './ConfirmationModal';
import { IFile } from './DocumentInterface';
import CustomDragLayer from './CustomDragLayer';

const { Sider, Content } = Layout;

export default function DocumentsContainer({ getFileIcon }: any) {
  const {
    currentDirectory,
    setCurrentDirectory,
    isFetchingFiles,
    filteredData,
    searchQuery,
    fetchFilesAndFolder,
    setSelectedFiles,
    allFiles,
    isConfirmationModalOpen,
    closeConfirmationModal,
    onConfirmDelete,
  } = useDocumentState();

  const data = filterItemsInDirectory(
    searchQuery.length > 0 ? filteredData : allFiles,
    currentDirectory
  );

  const [isEditing, setIsEditing] = useState(false);
  const [oldFileKey, setOldFileKey] = useState('');
  const newFileKeyRef = useRef(''); // Create a ref for newFileKey
  const editableRef: any = useRef(null);
  const [fileFromContextMenu, setFileFromContextMenu] = useState<IFile | null>(null);

  const handleClickOutside = (event: any) => {
    if (editableRef.current && !editableRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTextChange = (str: string) => {
    newFileKeyRef.current = str; // Update the ref with the latest value
  };

  const handleRenameFile = async () => {
    setIsEditing(false);
    // const updatedNewFileKey = updateFileKey(oldFileKey, newFileKeyRef.current);
    const { extension } = getFileNameFromKey(oldFileKey);
    const fileName = `${newFileKeyRef.current}.${extension}`;
    const name = generateUniqueFileName(
      allFiles?.length > 0 ? filterItemsInDirectory(allFiles, currentDirectory) : [],
      fileName
    );
    if (name) {
      const updatedNewFileKey = currentDirectory + name;
      // If new file is found then call rename file API
      if (updatedNewFileKey && updatedNewFileKey !== oldFileKey) {
        const res = await renameFile([{ oldFileKey, newFileKey: updatedNewFileKey }]);
        if (res) {
          fetchFilesAndFolder(currentDirectory);
          setOldFileKey('');
        }
      }
    } else {
      customNotification({ type: 'error', message: 'Something went wrong!' });
    }

    setIsEditing(false);
  };

  const onDelete = async () => {
    const res = await onConfirmDelete([fileFromContextMenu?.Key], 'deleteFromContext');
    if (res) {
      setFileFromContextMenu(null);
    }
  };
  const handleEditClick = (file: any) => {
    setIsEditing(true);
    setOldFileKey(file.Key);
  };

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    setCurrentDirectory(keys[0] as string);
    // Reset selected files on change directory
    setSelectedFiles([]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Layout style={{ height: '60vh' }}>
        <Sider
          width={300}
          style={{
            overflow: 'auto',
            background: '#ccc',
            padding: '10px',
            border: '5px solid #D3D3D3',
          }}
        >
          <DirectoryTreeComponent onSelect={onSelect} />
        </Sider>

        <Content
          id="content-container"
          style={{
            background: '#fff',
            border: '5px solid #ccc',
            padding: '20px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            overflowY: 'auto',
          }}
        >
          <Spin tip="Fetching Files..." spinning={isFetchingFiles}>
            {/* {isFetchingFiles && (
            <Space size={[16, 16]} wrap>
              {Array.from({ length: 30 }, (_, index) => index + 1).map((value) => (
                <SkeletonNode key={value} active={true}>
                  <CopyOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
                </SkeletonNode>
              ))}
            </Space>
          )} */}

            {data.length > 0 ? (
              // <Row gutter={[16, 16]}>
              <div>
                {data
                  .sort((a: any, b: any) => b.isDir - a.isDir)
                  .map((file: any) => (
                    <File
                      key={file.key}
                      file={file}
                      getFileIcon={getFileIcon}
                      editableRef={editableRef}
                      handleEditClick={handleEditClick}
                      oldFileKey={oldFileKey}
                      isEditing={isEditing}
                      handleTextChange={handleTextChange}
                      handlePressEnter={handleRenameFile}
                      handleSelectFileFromContext={(selectedFile: IFile) =>
                        setFileFromContextMenu(selectedFile)
                      }
                    />
                  ))}
              </div>
            ) : (
              // </Row>
              <div
                style={{
                  height: 500,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Empty />
              </div>
            )}
          </Spin>

          <ConfirmationModal
            isModalOpen={isConfirmationModalOpen.deleteFromContext}
            onOk={onDelete}
            onCancel={() => {
              closeConfirmationModal('deleteFromContext');
              setFileFromContextMenu(null);
            }}
            action="delete"
            id="deleteFromContext"
          />
        </Content>
        <CustomDragLayer />
      </Layout>
    </DndProvider>
  );
}
